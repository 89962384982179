body {
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #002223;
  color: white;
}

a {
  color: white;
}

p {
  font-size: 2rem;
}

.bold {
  font-weight: 900;
}

button {
  margin-left: 20px !important;
  margin-right: 20px !important;
  font-size: 1.25rem !important;
}
